<template>
    <div id="pageTable">
        <v-container grid-list-xl fluid>
            <v-row>
                <v-overlay :value="overlayDesgarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col lg="12">
                    <data-table
                        :url="url"
                        :columns="columns"
                        :tableName="'Detalle de asistencias'"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :perPage="[10, 25, 50, 100]"
                        @datosTabla="datosTabla"
                    >
                        <template  v-if="rol == 'admin'|| rol == 'admin-empresa' || rol == 'admin-sucursal' || rol == 'admin-depto'" slot="actionButtonsLeft">
                            <div style="padding-right: 12px;">
                                <v-speed-dial
                                    v-model="btnDoc"
                                    direction="bottom"
                                    open-on-hover
                                    transition="scale-transition"
                                    class="btn-download-cloud2"
                                >
                                    <template v-slot:activator>
                                        <v-btn
                                            v-model="btnDoc"
                                            color="#FFFFFF"
                                            fab
                                            class="btn-download-cloud2"
                                            elevation="3"
                                        >
                                            <v-icon color="#96999A" v-if="btnDoc">
                                                mdi-close
                                            </v-icon>
                                            <v-icon color="#96999A" v-text="'$get_app_outline'" v-else></v-icon>
                                        </v-btn>
                                    </template>
                                

                                    <v-btn
                                        fab
                                        small
                                        color="#FFFFFF"
                                        @click="exportar('pdf')"
                                    >
                                        <v-icon color="red">mdi-file-pdf</v-icon>
                                    </v-btn>
                                    <v-btn
                                        fab
                                        small
                                        color="#FFFFFF"
                                        @click="exportar('xlsx')"
                                    >
                                        <v-icon color="green">mdi-file-excel</v-icon>
                                    </v-btn>
                                </v-speed-dial>
                            </div>
                        </template>
                        <template slot="filters">
                            
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <template>
                                    <span class="RangoIngreso">Rango fecha</span>
                                </template>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_inicio_fil"
                                    label="Fecha inicial"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaInicial"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_final_fil"
                                    label="Fecha final"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaFinal"
                                />
                            </v-col>

                            <template v-if="rol == 'root'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="clientes"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Cliente"
                                        persistent-hint
                                        v-model="cliente_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="empresas"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Empresa"
                                        persistent-hint
                                        v-model="empresa_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa' || rol == 'admin-sucursal'">
                                <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="departamentos"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Departamento"
                                        persistent-hint
                                        v-model="departamento_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col v-if="rol != 'empleado'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="nombre_completo_empleados"
                                    item-text="nombre_completo"
                                    item-value="id"
                                    label="Empleado"
                                    persistent-hint
                                    v-model="empleado_fil"
                                >
                                </v-autocomplete>
                            </v-col>
                        </template>

                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td>
                                     <div>
                                        <v-list  color="transparent" class="py-0 my-0">
                                            <v-list-item class="py-0 my-0 px-0">
                                                <v-list-item-avatar  class="py-0 my-0">
                                                    <v-img  :src="urlAvatar+item.foto"></v-img>
                                                </v-list-item-avatar>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-list-item-content v-on="on" class="ml-0 py-0 my-0">
                                                            <v-list-item-title class="tblPrincipal">{{ item.nombre_empleado }} {{ item.paterno_empleado }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                    <span>{{ item.nombre_empleado }} {{ item.paterno_empleado }}</span>
                                                 </v-tooltip>  
                                            </v-list-item>
                                        </v-list>
                                    </div>
                                </td>
                                <td>
                                    <div class="tblPrincipal">
                                        {{ dateFormat(item.fecha) }}
                                    </div>
                                </td>

                                <td class="tipoLetra700Gris">
                                    <div class="tblPrincipal">
                                        {{ item.hora }}
                                    </div>
                                </td>

                                <td>
                                    <div class="tblOpciones">
                                        <v-icon v-if="item.origen == 'C'" class="tamIconoBoton iconoDelgadoBoton">fingerprint</v-icon>
                                        

                                        <v-bottom-sheet v-if="item.origen != 'C'" style="marginTop: -23px !important;">
                                            <template v-slot:activator="{on}">
                                                <!-- <v-icon v-on="on" class="tamIconoBoton iconoDelgadoBoton">place</v-icon> -->
                                                <v-icon v-on="on" v-if="item.origen == 'M'" class="tamIconoBoton iconoDelgadoBoton">smartphone</v-icon>
                                                <v-icon  v-on="on" v-if="item.origen == 'W'" class="tamIconoBoton iconoDelgadoBoton">laptop</v-icon>
                                            </template>

                                            <v-layout wrap>
                                                <v-col xs="12" sm="12" md="12">
                                                    <GmapMap
                                                        ref="mymap"
                                                        :center="JSON.parse(item.coordenadas)"
                                                        :zoom="14"
                                                        map-type-id="roadmap"
                                                        style="width: 100%; height: 400px"
                                                    >
                                                        <gmap-marker
                                                            :position="JSON.parse(item.coordenadas)"
                                                            :title="'Ubicación de chequeo'"
                                                            :draggable="false"
                                                        />
                                                    </GmapMap>
                                                </v-col>
                                            </v-layout>
                                        </v-bottom-sheet>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from "vue";
import empleadosApi from "../api/empleados";
import clienteApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "../api/sucursales";
import departamentoApi from "../api/departamentos";
import DataTable from "@/components/datatable/Datatable.vue";
import urlImg from "../plugins/Urlimagen";
import PerfectScrollbar from "perfect-scrollbar";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import Notify from "@/plugins/notify";

export default {
    components: {
        "data-table": DataTable,
        VuetifyDatePicker,
    },
    data() {
        return {
            url: "registros-checadores/find",
            columns: [
                {
                    label: "Empleado",
                    name: "empleado",
                    filterable: true,
                },
                {
                    label: "Fecha",
                    name: "fecha",
                    filterable: true,
                },
                {
                    label: "Hora",
                    name: "hora",
                    filterable: true,
                },
                {
                    label: "Tipo de registro",
                    name: "indicadores",
                    filterable: false,
                    align: 'center'
                },
            ],
            filters: {
                rol_name        : null,
                usuario_id      : null,
                activo          : true,
                paginate        : true,
                include_padres  : true,
                include_horario : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                empleado_id     : null,
                min_fecha       : null,
                max_fecha       : null,
                order_by        : 'fecha',
            },

            datosLogin      : "",
            menu_final      : false,
            menu_final_c    : false,
            menu_inicio     : false,
            menu_inicio_c   : false,
            fecha_final     : null,
            fecha_final_fil : null,
            fecha_inicio    : null,
            fecha_inicio_fil: null,
            empleados       : [],

            inicio          : "",
            final           : "",
            rol             : null,
            empleado_data   : null,
            cliente_value   : null,
            empresa_value   : null,
            sucursal_value  : null,
            departamento_value  : null,
            clientes        : [],
            empresas        : [],
            sucursales      : [],
            departamentos   : [],
            empleado_fil    : null,
            nombre_completo_empleados: [],
            urlAvatar       : urlImg.getURL(),
            btnDoc          :false,
            overlayDesgarga : false,
            frase           :'Descargando reporte',
            numRegistros    : 0,
        };
    },
    watch: {
         cliente_value: function(val, oldVal){
            if(val != null){
                this.cambiaCliente(val);
                if(val != oldVal && oldVal != null){
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.sucursales = [];
                }
                this.getEmpleados();
            }
        },
        empresa_value: function(val, oldVal) {
            if(val != null){
                this.cambiaEmpresa(val);
                if(val != oldVal && oldVal != null ){
                    this.sucursal_value = null;
                }
                this.getEmpleados();
            }
        },
        sucursal_value: function(val, oldVal){
            if(val != null){
                this.getDepartamentos(val);
            }
        },
        departamento_value: function(val, oldVal){
            if(val != null){
                this.getEmpleados();
            }
        }
    },
    methods: {
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.empleado_data = this.$session.get("empleado");
            this.rol = this.datosLogin.rol.name;
            this.filters.rol_name = this.datosLogin.rol.name;
            this.filters.usuario_id = this.datosLogin.id;

            if (this.rol == "admin") {
                this.filters.cliente_id = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.filters.empresa_id = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                this.filters.sucursal_id = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                this.filters.departamento_id = this.datosLogin.departamento_id;
            } else if (this.rol == "empleado") {
                this.filters.empleado_id = this.datosLogin.empleado_id;
            }

            if (this.fecha_inicio != null) {
                this.filters.min_fecha = this.fecha_inicio;
            }

            if (this.fecha_inicio != null) {
                this.filters.max_fecha = this.fecha_final;
            }
        },
        formateaDia(fechaOrigen) {
            var mesesNombres = [
                "Ene",
                "Feb",
                "Mar",
                "Abr",
                "May",
                "Jun",
                "Jul",
                "Ago",
                "Sep",
                "Oct",
                "Nov",
                "Dic",
            ];

            if (fechaOrigen != undefined) {
                let fecha = fechaOrigen.split("-");
                return (fecha[2] + " " + mesesNombres[fecha[1] - 1] + " " + fecha[0]); 
                /* element['fecha'].replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'); */
            }
        },
        formateNombre(empleadoOrigen = null, opcion = null) {
            let nombre = "No registrado.";
            let apellido_paterno = "";
            let apellido_materno = "";
            let clave = "NA";

            if (empleadoOrigen != null) {
                nombre = empleadoOrigen.nombre;
                apellido_paterno = empleadoOrigen.apellido_paterno;
                clave = empleadoOrigen.clave;
                if (empleadoOrigen.apellido_materno != undefined) {
                apellido_materno = empleadoOrigen.apellido_materno;
                }
            }

            if (opcion == "NOMBRE") {
                return nombre + " " + apellido_paterno + " " + apellido_materno;
            } else if (opcion == "CLAVE") {
                return clave;
            }
        },
        async getClientes(){
            let parametros = { activo: true, paginate: false};
            await clienteApi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async cambiaCliente(cliente_id, accion = null) {
            let parametros = { activo: true, paginate: false, cliente_id: cliente_id };
            await empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
            })
            .catch((err) => {
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async cambiaEmpresa(empresa, accion = null) {
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi.getSucursales(parametros).then((response) => {
                this.sucursales = response.data;
            })
            .catch((err) => {
                console.log(err);
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async getDepartamentos(sucursal, accion = null) {
            let parametros = { activo: true, paginate: false, sucursal_id: sucursal };
            await departamentoApi.getDepartamentos(parametros).then((response) => {
                this.departamentos = response.data;
            })
            .catch((err) => {
                console.log(err);
                this.respuesta_error(err);
            });
        },
        resetvalue() {
            this.fecha_inicio   = null;
            this.fecha_final    = null;
            this.cliente_value  = null;
            this.empresa_value  = null;
            this.sucursal_value = null;
            this.departamento_value = null;
            this.clientes       = [];
            this.empresas       = [];
            this.sucursales     = [];
            this.departamentos  = [];
            this.nombre_completo_empleados = [];
        },
        setFilters() {
            let fecha_inicio    = this.filters.min_fecha;
            let fecha_final     = this.filters.max_fecha;
            let empleado        = this.empleado_fil;
            let cliente         = this.cliente_value;
            let empresa         = this.empresa_value;
            let sucursal        = this.sucursal_value;
            let departamento    = this.departamento_value;

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            } else if (this.rol == "admin-depto") {
                departamento = this.datosLogin.departamento_id;
            } else if (this.rol == "empleado") {
                empleado = this.datosLogin.empleado_id;
            }

            if (this.fecha_inicio_fil != null) {
                fecha_inicio = this.fecha_inicio_fil;
            }
            if (this.fecha_final_fil != null) {
                fecha_final = this.fecha_final_fil;
            }

            let filterParams = {
                cliente_id: cliente,
                empresa_id: empresa,
                sucursal_id: sucursal,
                departamento_id: departamento,
                empleado_id: empleado,
                min_fecha: fecha_inicio,
                max_fecha: fecha_final,
            };

            let defaultParams = {
                activo: true,
                paginate: true,
                include_padres: true,
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.filters = defaultParams;
            this.fecha_inicio_fil = null;
            this.fecha_final_fil = null;
            this.cliente_value = null;
            this.empresa_value =  null;
            this.sucursal_value = null;
            this.departamento_value = null;
        },
        async loadModalData() {
            this.empleado_fil = null;
            let cliente = null;
            let empresa = null;
            let sucursal = null;
            let depto = null;
            await this.resetvalue();

            this.filters.min_fecha = this.inicio;
            this.filters.max_fecha = this.final;

            this.fecha_inicio_fil = null;
            this.fecha_final_fil = null;
            if(this.$refs.FechaInicial != undefined || this.$refs.FechaInicial != null){
                this.$refs.FechaInicial.resetDate();
            }
            if(this.$refs.FechaFinal != undefined || this.$refs.FechaFinal != null){
                this.$refs.FechaFinal.resetDate();
            }
            

            if (this.rol == "root") {
                this.getClientes();
            }
            if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            if (this.rol == "admin-empresa") {
                
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            if (this.rol == "admin-sucursal") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
            }
            if (this.rol == "admin-depto") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.departamento_value = this.datosLogin.departamento_id;
            }

        },
        async getEmpleados(){

            let param = {
                activo          : true,
                paginate        : false,
                cliente_id      : this.cliente_value,
                empresa_id      : this.empresa_value,
                sucursal_id     : this.sucursal_value,
                departamento_id : this.departamento_value,
                rol_name        : this.datosLogin.rol.name,
                usuario_id      : this.datosLogin.id,
            };
            empleadosApi.getEmpleados(param).then((response) => {
                this.nombre_completo_empleados = response.data;
            })
            .catch((err) => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        init() {
            const elementScroll = document.querySelector("#scroll");
            if (elementScroll) {
                const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollY: true,
                });
            }
            $(".ps__thumb-x").css("position", "fixed");
        },
        async exportar(tipo_doc = null) {
            if(this.numRegistros == 0){
                Notify.ErrorToast("No hay registros para realizar la descarga del reporte.");
                return;
            }
            let self = this;
            this.overlayDesgarga = true;
            let url = "reportes/historico_checadas";
            const FileDownload = require("js-file-download");
            var today = new Date();

            let nombre;
            if(tipo_doc == 'xlsx'){
                nombre = "historico_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".xlsx";
            }
            else{
                nombre = "historico_" +
                today.getDate() +
                "_" +
                (today.getMonth() + 1) +
                "_" +
                today.getFullYear() +
                ".pdf";
            }

            var param = this.filters;
            param.paginate = false;
            param.tipo = tipo_doc;
            const object1 = param;
            try {
                await Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                    params: object1,
                }).then((response, status, xhr) => {
                    this.overlayDesgarga = false;
                    FileDownload(response.data, nombre);
                });
            } catch (e) {
                this.overlayDesgarga = false;
                var message = null;
                console.log(e)
            }
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        datosTabla(datos){
            this.numRegistros = datos.data.length;
        }
    },
    created() {
        let fecha = new Date();
        let mes = String(fecha.getMonth() + 1);
        let dia = String(fecha.getDate());

        if (mes.length < 2) {
            mes = "0" + mes;
        }
        if (dia.length < 2) {
            dia = "0" + dia;
        }

        this.inicio = fecha.getFullYear() + "-" + mes + "-01";
        this.fecha_inicio = fecha.getFullYear() + "-" + mes + "-01";

        this.final = fecha.getFullYear() + "-" + mes + "-" + dia;
        this.fecha_final = fecha.getFullYear() + "-" + mes + "-" + dia;

        this.listar();
    },
    mounted() {
        this.init();
    },
};
</script>
<style scoped>
    .RangoIngreso {
        font-family: Montserrat !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        color: #25282b !important;
        margin-top: -3%;
    }
</style>
